export class Operator {
  id: string;
  // tslint:disable-next-line: no-reserved-keywords
  type: string;
  attributes: Attributes;

  constructor(that: any) {
    Object.assign(this, that);
  }
}

interface Attributes {
  email: string;
  email_confirmed: boolean;
  name: string;
  level: string;
  visible: boolean;
}
