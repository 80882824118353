// Extends Service
import { CurrentUserFacadeService } from '../current-user/current-user.facade.service';
var REJECTED_STATUS = 'rejected';
var FINALIZED_MANDATORY_STATUSES = ['completed', REJECTED_STATUS];
var CANT_EDIT_STATUSES = ['on_hold'];
var PermitsService = /** @class */ (function () {
    function PermitsService(currentUserServiceFacade) {
        this.currentUserServiceFacade = currentUserServiceFacade;
    }
    Object.defineProperty(PermitsService.prototype, "serviceStatus", {
        get: function () {
            return this.service ? this.service.status : '';
        },
        enumerable: true,
        configurable: true
    });
    PermitsService.prototype.canEditService = function () {
        if (CANT_EDIT_STATUSES.includes(this.serviceStatus)) {
            return false;
        }
        if (FINALIZED_MANDATORY_STATUSES.includes(this.serviceStatus)) {
            if (!this.currentUserServiceFacade.isOperator()) {
                return false;
            }
            if (this.currentUserServiceFacade.hasLevelRegular() ||
                this.currentUserServiceFacade.hasLevelFinance()) {
                return false;
            }
            return true;
        }
        if (this.currentUserServiceFacade.isOperator() &&
            this.currentUserServiceFacade.hasLevelRegular() &&
            this.currentUserServiceFacade.currentServiceIsMine) {
            return true;
        }
        if (this.currentUserServiceFacade.isOperator() &&
            !this.currentUserServiceFacade.hasLevelRegular()) {
            return true;
        }
        return false;
    };
    PermitsService.prototype.canEditBudget = function (budget) {
        return !budget.locked && this.serviceStatus !== REJECTED_STATUS;
    };
    PermitsService.prototype.canCancelService = function () {
        return (!CANT_EDIT_STATUSES.includes(this.serviceStatus) &&
            !FINALIZED_MANDATORY_STATUSES.includes(this.serviceStatus));
    };
    PermitsService.prototype.canCancelInvoice = function () {
        return this.isAdminOrFinanceOperator();
    };
    PermitsService.prototype.canCancelReceiptAcquittance = function () {
        return this.isAdminOrFinanceOperator();
    };
    PermitsService.prototype.canClientEditComplaint = function () {
        return (this.currentUserServiceFacade.isClient() &&
            !CANT_EDIT_STATUSES.includes(this.serviceStatus));
    };
    PermitsService.prototype.isAdminOrFinanceOperator = function () {
        return (this.currentUserServiceFacade.isOperator() &&
            (this.currentUserServiceFacade.hasLevelSuperAdmin() ||
                this.currentUserServiceFacade.hasLevelAdmin() ||
                this.currentUserServiceFacade.hasLevelFinance()));
    };
    return PermitsService;
}());
export { PermitsService };
