<div class="dialog-header">
  <div class="dialog-header-title"></div>
  <div class="dialog-close-button">
    <button
      (click)="close()"
      class="fmx-btn fmx-btn-clear fmx-btn-icon fmx-btn-secondary"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div mat-dialog-content class="dialog-content">
  <div
    class="m-b-15 modal_image_container d-flex flex-column flex-h-center flex-v-center"
  >
    <img [src]="preview" (load)="onImageLoad()" />
    <div class="ml-4 spinner-loader" *ngIf="loader"></div>
  </div>
</div>

<div class="dialog-footer">
  <button
    (click)="downloadFile()"
    class="fmx-btn fmx-btn-sm fmx-btn-green m-l-5 m-r-5"
  >
    Descargar
  </button>
  <a #downloadLink class="hidden" [href]="url" target="_blank" download></a>
</div>
