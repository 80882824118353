import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// Models
import { SelectOption } from '@app/core/models/select-option.model';
import { Operator } from '@app/features/configuration/containers/operators/models/operator.model';
import { DataPage } from '@app/fmx-shared/interfaces/data-page.interface';

// Utils
import { generateResponseDataPage } from '@app/fmx-shared/utils/data-utils';
import { clone } from 'underscore';

@Injectable()
export class OperatorsService {
  listOperatorsFull: DataPage;

  constructor(private http: HttpClient) {}

  createOperator(data: any): Observable<any> {
    const endpoint = 'operators';

    return this.http.post(endpoint, data);
  }

  updateOperator(idOperator: string, data: any): Observable<any> {
    const endpoint = `operators/${idOperator}`;

    return this.http.patch(endpoint, data);
  }

  getOperatorsByPage(
    page: number,
    search: string = '',
    operatorIdToInsert?: string[]
  ): Observable<DataPage> {
    let params = new HttpParams()
      .set('sort_by', 'name')
      .set('sort_dir', 'asc')
      .set('page', page.toString());

    if (search) {
      params = params.append('name', search);
    }

    const endpoint = 'operators';

    return this.http.get(endpoint, { params }).pipe(
      map(generateResponseDataPage),
      tap((response: DataPage) => (this.listOperatorsFull = clone(response))),
      map(() => {
        const dataResponse: DataPage = clone(this.listOperatorsFull);

        dataResponse.data = this.listOperatorsFull.data.filter(
          (operator: SelectOption) => operator.element.attributes.visible
        );

        if (operatorIdToInsert) {
          operatorIdToInsert.forEach((id: string) => {
            if (
              !dataResponse.data.some(
                (item: SelectOption) => item.element.id === id.toString()
              )
            ) {
              const findOperatorToInsert: SelectOption = this.listOperatorsFull.data.find(
                (item: SelectOption) => item.element.id === id.toString()
              );

              if (findOperatorToInsert) {
                dataResponse.data.push(findOperatorToInsert);
              }
            }
          });
        }

        return dataResponse;
      })
    );
  }

  getOperatorsByPages(
    filter: any,
    sort: any,
    pagination: any
  ): Observable<any> {
    const endpoint = `operators`;

    const params = {
      ...filter,
      ...sort,
      ...pagination
    };

    return this.http.get(endpoint, { params });
  }

  getOperator(operatorId: string): Observable<Operator> {
    const endpoint = `operators/${operatorId}`;

    return this.http
      .get(endpoint)
      .pipe(map((item: any) => new Operator(item.data)));
  }
}
