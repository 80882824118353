import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'fmx-image-modal',
  templateUrl: './fmx-image-modal.component.html'
})
export class FmxImageModalComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;

  url: string;
  preview: string;
  loader = true;

  constructor(
    private dialogRef: MatDialogRef<FmxImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    const image = this.data && this.data.image;
    this.preview = image.attributes.file.default.url || '';
    this.url = image.attributes.file.original.url || '';
  }

  close() {
    this.dialogRef.close();
  }

  downloadFile() {
    this.downloadLink.nativeElement.click();
  }

  onImageLoad(): void {
    this.loader = false;
  }
}
