// tslint:disable: no-reserved-keywords
import { Injectable } from '@angular/core';

import { forkJoin, Observable, of } from 'rxjs';
import { finalize, catchError, tap, map } from 'rxjs/operators';

import * as _ from 'lodash';

// Services
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
import { ServiceDetailState } from './../../state/service-detail.state';
import { ServiceDetailService } from './../service-detail/service-detail.service';

// Utils
import {
  getBudgetFormat,
  prepareBudget
} from '../../utils/format-budget.utils';

// Extends Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { CurrentUserService } from '@app/core/services/current-user/current-user.service';

// Models
import { Complaint } from '@app/features/complaints/models/complaint.model';
import { HomeServiceChecklist } from '../../models/home-service-checklist.model';

@Injectable()
export class ServiceDetailFacadeService extends CurrentUserFacadeService {
  private _serviceId: string;
  private _selectedService: any;

  constructor(
    private state: ServiceDetailState,
    private httpService: ServiceDetailService,
    private toastr: FmxToastr,
    protected currentUserService: CurrentUserService
  ) {
    super(currentUserService);
  }

  set serviceId(value: string) {
    this._serviceId = value;
  }

  get serviceId(): string {
    return this._serviceId;
  }

  set selectedService(value: any) {
    this._selectedService = value;
  }

  get selectedService() {
    return this._selectedService;
  }

  get statusService(): string {
    return this.selectedService.attributes.status;
  }

  get serviceCompleted(): boolean {
    return this.statusService === 'completed';
  }

  get isHomeService(): boolean {
    return this.selectedService && this.selectedService.attributes.home_service;
  }

  reset() {
    this.serviceId = null;
    this.selectedService = null;
    this.state.reset();
  }

  getService(): Observable<any> {
    this.state.set('loadingSub', true);

    return this.httpService.getService(this.serviceId).pipe(
      tap((service: any) => (this.selectedService = service)),
      finalize(() => this.state.set('loadingSub', false))
    );
  }

  loadService(isUpdate: boolean = false) {
    this.state.set('loadingSub', true);

    this.httpService
      .getService(this.serviceId)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe((service: any) => {
        this.selectedService = service;
        this.state.set('serviceSub', service);
        this.state.set('loadedSub', true);

        if (!isUpdate) {
          this.loadVisits();
          this.loadBudgets();
          this.loadComments();
          this.loadFiles();
          this.loadHistoric();
          this.loadHoldData();
          this.loadComplaints();

          if (!this.isProvider()) {
            this.getSpace(service.attributes.space_id);
          }

          if (service.attributes.additional_works_enabled) {
            this.loadAdditionalWorks();
          }

          if (this.isHomeService) {
            this.loadHomeServiceChecklists();
          }

          if (this.isOperator()) {
            this.loadNotes();
            this.loadBreaches();
            this.loadRelatedServices();

            if (this.serviceCompleted) {
              this.loadRepays();
              this.loadRecharges();
            }
          }

          if (this.isProvider() && this.serviceCompleted) {
            this.loadRecharges();
          }

          if (this.isClient() && this.serviceCompleted) {
            this.loadRepays();
          }
        }
      });
  }

  updateService(data: any) {
    this.state.set('loadingSub', true);
    this.httpService
      .updateService(this.serviceId, data)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe(
        () => {
          this.toastr.success('Servicio actualizado correctamente');
          this.loadService(false);
        },
        () => {
          this.toastr.error(
            'Error al actualizar el servicio, por favor pruebe en unos minutos.'
          );
        }
      );
  }

  documentationRejectService(data: any) {
    this.state.set('loadingSub', true);
    this.httpService
      .documentationRejectService(this.serviceId, data)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe(
        () => {
          this.toastr.success('Estado del servicio actualizado correctamente');
          this.loadService(true);
        },
        () => {
          this.toastr.error(
            'Error al actualizar el estado servicio, por favor pruebe en unos minutos.'
          );
        }
      );
  }

  documentationConfirmService() {
    const data = {};

    this.state.set('loadingSub', true);
    this.httpService
      .documentationConfirmService(this.serviceId, data)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe(
        () => {
          this.toastr.success('Estado del servicio actualizado correctamente');
          this.loadService(true);
        },
        () => {
          this.toastr.error(
            'Error al actualizar el estado servicio, por favor pruebe en unos minutos.'
          );
        }
      );
  }

  finalizeService(params: any) {
    const data = { ...params };

    this.state.set('loadingSub', true);
    this.httpService
      .finalizeService(this.serviceId, data)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe(
        () => {
          this.toastr.success('Estado del servicio actualizado correctamente');
          this.loadService(true);
        },
        () => {
          this.toastr.error(
            'Error al actualizar el estado servicio, por favor pruebe en unos minutos.'
          );
        }
      );
  }

  completeService(params: any) {
    const data = { ...params };

    this.state.set('loadingSub', true);
    this.httpService
      .completeService(this.serviceId, data)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe(
        () => {
          this.toastr.success('Estado del servicio actualizado correctamente');
          this.loadService(true);
        },
        () => {
          this.toastr.error(
            'Error al actualizar el estado servicio, por favor pruebe en unos minutos.'
          );
        }
      );
  }

  rejectionService(data: any) {
    this.state.set('loadingSub', true);
    this.httpService
      .rejectionService(this.serviceId, data)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe(
        () => {
          this.toastr.success('Servicio rechazado');
          this.loadService(true);
        },
        () => {
          this.toastr.error(
            'Error al rechazar el servicio, por favor pruebe en unos minutos.'
          );
        }
      );
  }

  authorizationService() {
    const data = { service_authorization: { dummy: true } };
    this.state.set('loadingSub', true);
    this.httpService
      .authorizationService(this.serviceId, data)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe(
        () => {
          this.toastr.success('Estado del servicio actualizado correctamente');
          this.loadService(true);
        },
        () => {
          this.toastr.error(
            'Error al actualizar el estado servicio, por favor pruebe en unos minutos.'
          );
        }
      );
  }

  reOpeningService() {
    this.state.set('loadingSub', true);
    this.httpService
      .reOpeningService(this.serviceId)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe(
        () => {
          this.toastr.success('Estado del servicio actualizado correctamente');
          this.loadService(true);
        },
        () => {
          this.toastr.error(
            'Error al actualizar el estado servicio, por favor pruebe en unos minutos.'
          );
        }
      );
  }

  updateFacilityManagerTransfer(data: any) {
    this.state.set('loadingSub', true);
    this.httpService
      .updateFacilityManagerTransfer(this.serviceId, data)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe(
        () => {
          this.toastr.success('Servicio actualizado correctamente');
          this.loadService(true);
        },
        () => {
          this.toastr.error(
            'Error al actualizar el servicio, por favor pruebe en unos minutos.'
          );
        }
      );
  }

  updateNetworkManagerTransfer(data: any) {
    this.state.set('loadingSub', true);
    this.httpService
      .updateNetworkManagerTransfer(this.serviceId, data)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe(
        () => {
          this.toastr.success('Servicio actualizado correctamente');
          this.loadService(true);
        },
        () => {
          this.toastr.error(
            'Error al actualizar el servicio, por favor pruebe en unos minutos.'
          );
        }
      );
  }

  updateProviderTransfer(data: any) {
    this.state.set('loadingSub', true);

    this.httpService
      .updateProviderTransfer(this.serviceId, data)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe(
        () => {
          this.toastr.success('Servicio actualizado correctamente');
          this.loadService(false);
        },
        (error: any) => {
          const errors = error.error.errors;
          this.toastr.error(errors.provider_id[0]);
        }
      );
  }

  getInvoiceService(id: string): Observable<any> {
    return this.httpService.getInvoiceService(id).pipe(
      catchError(() => {
        this.toastr.error(
          'Error al descargar la factura, por favor pruebe en unos minutos'
        );

        return of(null);
      })
    );
  }

  getReceiptService(id: string): Observable<any> {
    return this.httpService.getReceiptService(id).pipe(
      catchError(() => {
        this.toastr.error(
          'Error al descargar la factura, por favor pruebe en unos minutos'
        );

        return of(null);
      })
    );
  }

  getChecklistService(id: string): Observable<any> {
    return this.httpService.getChecklistService(id).pipe(
      catchError(() => {
        this.toastr.error(
          'Error al descargar la gama, por favor pruebe en unos minutos'
        );

        return of(null);
      })
    );
  }

  getGroundingCertificate(id: string): Observable<any> {
    return this.httpService.getGroundingCertificate(id).pipe(
      catchError(() => {
        this.toastr.error(
          'Error al descargar el certificado de puesta a tierra, por favor pruebe en unos minutos'
        );

        return of(null);
      })
    );
  }

  loadVisits() {
    this.state.set('loadingVisitsSub', true);
    this.httpService
      .getVisits(this.serviceId)
      .pipe(
        finalize(() => this.state.set('loadingVisitsSub', false)),
        tap((visits: any[]) => {
          let status = '';
          if (visits && visits.length > 0) {
            const visitPending = visits.find(
              (visit: any) => visit.attributes.status === 'pending'
            );
            if (visitPending) {
              status = visitPending.attributes.started_at
                ? visitPending.attributes.started_at
                : visitPending.attributes.starts_at;
            } else {
              status = 'Finalizada';
            }
          } else {
            status = 'No programada';
          }
          this.state.set('visitStatusSub', status);
        })
      )
      .subscribe((visits: any[]) => {
        this.state.set('visitsSub', visits);
      });
  }

  createVisit(data: any) {
    this.state.set('loadingVisitsSub', true);
    this.httpService.createVisit(this.serviceId, data).subscribe(
      () => {
        this.toastr.success('Visita creada correctamente');
        this.loadVisits();
        this.loadService(false);
      },
      () => {
        this.toastr.error(
          'Error al crear la visita, por favor pruebe en unos minutos.'
        );
      }
    );
  }

  updateVisit(visitID: string, params: any = {}) {
    this.httpService.updateVisit(this.serviceId, visitID, params).subscribe(
      () => {
        this.toastr.success('Visita actualizada correctamente');
        this.loadVisits();
        this.loadFiles();
        this.loadService(false);
      },
      () => {
        this.toastr.error(
          'Error al actualizar la visita, por favor pruebe en unos minutos.'
        );
        this.loadFiles();
      }
    );
  }

  finishVisit(visitID: string, params: any = {}) {
    this.httpService.finishVisit(this.serviceId, visitID, params).subscribe(
      () => {
        this.toastr.success('Visita finalizada correctamente');
        this.loadVisits();
        this.loadFiles();
        this.loadService(false);
      },
      () => {
        this.toastr.error(
          'Error al finalizar la visita, por favor pruebe en unos minutos.'
        );
        this.loadFiles();
      }
    );
  }

  getServicePdfVisit(visitId: string): Observable<any> {
    return this.httpService.getServicePdfVisit(this.serviceId, visitId).pipe(
      catchError(() => {
        this.toastr.error(
          'Error al descargar el parte de trabajo, por favor pruebe en unos minutos'
        );

        return of(null);
      })
    );
  }

  loadBudgets() {
    const listAssessments = [];
    this.state.set('loadingBudgetsSub', true);

    this.httpService
      .getServiceBudgets(this.serviceId)
      .subscribe(async (budgets: any[]) => {
        const budgetsSort = budgets.map((item: any) => item.id);
        for (const budget of budgetsSort) {
          await this.httpService
            .getServiceBudget(this.serviceId, budget)
            .toPromise()
            .then((response: any) => {
              listAssessments.push(prepareBudget(response));
            });
        }

        this.state.set('budgetsSub', listAssessments);
        this.state.set('loadingBudgetsSub', false);
      });
  }

  loadBudget(budgetId: string) {
    this.httpService
      .getServiceBudget(this.serviceId, budgetId)
      .subscribe((response: any) => {
        const budget = prepareBudget(response);

        this.setSelectedBudget(
          getBudgetFormat(budget.assessment, budget.items)
        );
      });
  }

  loadAdditionalWork(additionalWork: number) {
    this.httpService
      .getAdditionalWorkInfo(this.serviceId, additionalWork)
      .then((response: any) => {
        const budget = prepareBudget(response);

        this.setSelectedBudget(
          getBudgetFormat(budget.assessment, budget.items)
        );
      });
  }

  getServicePdfAssesment(
    assesmentId: string,
    type: string,
    additionalWork: boolean
  ): Observable<any> {
    return this.httpService
      .getServicePdfAssesment(this.serviceId, assesmentId, type, additionalWork)
      .pipe(
        catchError(() => {
          this.toastr.error(
            'Error al descargar el presupuesto, por favor pruebe en unos minutos'
          );

          return of(null);
        })
      );
  }

  createServiceBudget(body: any) {
    this.state.set('loadingBudgetsSub', true);
    this.httpService.createServiceBudget(this.serviceId, body).subscribe(
      (response: any) => {
        this.loadBudget(response.data.id);
        this.toastr.success('Presupuesto creado correctamente');
      },
      () => {
        this.toastr.error(
          'Error al actualizar el presupuesto, por favor pruebe en unos minutos.'
        );
      }
    );
  }

  updateServiceBudget(
    budgetId: string,
    body: any,
    reloadBudgets: boolean = false,
    reloadService: boolean = false
  ) {
    this.state.set('loadingBudgetsSub', true);
    this.httpService
      .updateServiceBudget(this.serviceId, budgetId, body)
      .pipe(finalize(() => this.state.set('loadingBudgetsSub', false)))
      .subscribe(
        () => {
          if (reloadBudgets) {
            this.loadBudgets();
          } else {
            this.loadBudget(budgetId);
          }

          if (reloadService) {
            this.loadService(true);
          }
          this.toastr.success('Presupuesto actualizada correctamente');
        },
        () => {
          this.toastr.error(
            'Error al actualizar el presupuesto, por favor pruebe en unos minutos.'
          );
        }
      );
  }

  setSelectedBudget(budget: any) {
    this.state.set('selectedBudgetSub', budget);
  }

  getSelectedBudget() {
    return this.state.get('selectedBudgetSub');
  }

  confirmationBudget(budgetId: number) {
    this.state.set('loadingSub', true);
    this.httpService
      .confirmationBudget(this.serviceId, budgetId)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe(
        () => {
          this.loadBudgets();
          this.loadService(true);
          this.toastr.success('Presupuesto enviado a revisión de Famaex');
        },
        () => {
          this.toastr.error(
            'Error al enviar revisión del presupuesto, por favor pruebe en unos minutos.'
          );
        }
      );
  }

  rejectionBudget(budgetId: number, data: any) {
    this.state.set('loadingSub', true);
    this.httpService
      .rejectionBudget(this.serviceId, budgetId, data)
      .pipe(finalize(() => this.state.set('loadingSub', false)))
      .subscribe(
        () => {
          this.toastr.success('Presupuesto rechazado');
          this.loadBudgets();
        },
        () => {
          this.toastr.error(
            'Error al rechazar el presupuesto, por favor pruebe en unos minutos.'
          );
        }
      );
  }

  loadComments() {
    this.state.set('loadingCommentsSub', true);
    this.httpService
      .getServiceComments(this.serviceId)
      .pipe(finalize(() => this.state.set('loadingCommentsSub', false)))
      .subscribe((comments: any[]) => {
        this.state.set('commentsSub', comments);
      });
  }

  createComment(data: any) {
    this.state.set('loadingCommentsSub', true);
    this.httpService
      .createServiceComment(this.serviceId, data)
      .subscribe(() => {
        this.loadComments();
      });
  }

  deleteComment(commentId: string) {
    this.state.set('loadingCommentsSub', true);
    this.httpService.deleteComment(this.serviceId, commentId).subscribe(() => {
      this.loadComments();
    });
  }

  loadFiles() {
    this.state.set('loadingFilesSub', true);
    this.httpService
      .getServiceFiles(this.serviceId)
      .pipe(finalize(() => this.state.set('loadingFilesSub', false)))
      .subscribe((files: any) => {
        this.state.set('filesSub', files);
      });
  }

  getServiceFile(fileId: string): Observable<any> {
    return this.httpService.getServiceFile(this.serviceId, fileId);
  }

  createServiceImage(body: any): Observable<any> {
    return this.httpService.createServiceImage(this.serviceId, body);
  }

  createServiceDocument(body: any): Observable<any> {
    return this.httpService.createServiceDocument(this.serviceId, body);
  }

  deleteServiceImage(imageId: string) {
    this.httpService
      .deleteServiceImage(this.serviceId, imageId)
      .subscribe(() => this.loadFiles());
  }

  deleteServiceFile(fileId: string) {
    this.httpService
      .deleteServiceFile(this.serviceId, fileId)
      .subscribe(() => this.loadFiles());
  }

  deleteVisitImage(visitId: string, imageId: string) {
    this.httpService
      .deleteVisitImage(this.serviceId, visitId, imageId)
      .subscribe(() => this.loadFiles());
  }

  deleteVisitFile(visitId: string, fileId: string) {
    this.httpService
      .deleteVisitFile(this.serviceId, visitId, fileId)
      .subscribe(() => this.loadFiles());
  }

  loadNotes() {
    this.state.set('loadingNotesSub', true);
    this.httpService
      .getServiceNotes(this.serviceId)
      .pipe(finalize(() => this.state.set('loadingNotesSub', false)))
      .subscribe((notes: any[]) => {
        this.state.set('notesSub', notes);
      });
  }

  createServiceNote(body: any): Observable<any> {
    return this.httpService.createServiceNote(this.serviceId, body);
  }

  createServiceNoteSuccess() {
    this.toastr.success('Nota creada correctamente');
    this.loadNotes();
  }

  updateServiceNote(data: any): Observable<any> {
    return this.httpService.updateServiceNote(
      this.serviceId,
      data.id,
      data.body
    );
  }

  updateServiceNoteSuccess() {
    this.toastr.success('Nota actualizada correctamente');
    this.loadNotes();
  }

  loadHoldData() {
    return this.httpService.loadHoldData(this.serviceId).pipe(
      map((response: any) => response && response.data),
      map((data: any[]) => {
        // tslint:disable-next-line:underscore-consistent-invocation
        const sortedData = _.sortBy(data, 'id');

        return (
          (sortedData &&
            sortedData.length > 0 &&
            sortedData[sortedData.length - 1]) ||
          null
        );
      })
    );
  }

  updateFrozenService(data: any): Observable<any> {
    return this.httpService.updateFrozenService(this.serviceId, data).pipe(
      tap(() => {
        this.loadService(true);
      })
    );
  }

  frozenService(data: any): Observable<any> {
    return this.httpService.frozenService(this.serviceId, data).pipe(
      tap(() => {
        this.loadService(true);
      })
    );
  }

  unfrozenService(): Observable<any> {
    return this.httpService.unfrozenService(this.serviceId).pipe(
      tap(() => {
        this.loadService(true);
      })
    );
  }

  loadBreaches() {
    this.state.set('loadingBreachesSub', true);
    this.httpService
      .getServiceBreaches(this.serviceId)
      .pipe(finalize(() => this.state.set('loadingBreachesSub', false)))
      .subscribe((breaches: any[]) => {
        this.state.set('breachesSub', breaches);
      });
  }

  loadComplaints() {
    this.state.set('loadingComplaintsSub', true);
    this.httpService
      .getServiceComplaints(this.serviceId)
      .pipe(finalize(() => this.state.set('loadingComplaintsSub', false)))
      .subscribe((complaints: Complaint[]) =>
        this.state.set('complaintsSub', complaints)
      );
  }

  loadHistoric() {
    this.state.set('loadingHistoricSub', true);
    this.httpService.expandObservable = true;
    const listHistoric = [];

    this.httpService
      .getServiceHistoric(this.serviceId)
      .pipe(finalize(() => this.state.set('loadingHistoricSub', false)))
      .subscribe((logs: any) => {
        listHistoric.push(...logs.data);

        if (
          logs.meta.current_page === logs.meta.last_page ||
          logs.meta.last_page === 0
        ) {
          this.httpService.expandObservable = false;
          this.state.set('historicSub', listHistoric);
        }
      });
  }

  loadRepays() {
    this.state.set('loadingRepaysSub', true);
    this.httpService
      .getServiceRepays(this.serviceId)
      .pipe(finalize(() => this.state.set('loadingRepaysSub', false)))
      .subscribe((repays: any[]) => {
        this.state.set('repaysSub', repays);
      });
  }

  createRepay(data: any): Observable<any> {
    return this.httpService.createRepayService(this.serviceId, data);
  }

  updateRepay(repayId: string, data: any): Observable<any> {
    return this.httpService.updateRepayService(this.serviceId, repayId, data);
  }

  deleteRepay(repayId: string) {
    this.httpService
      .deleteRepayService(this.serviceId, repayId)
      .subscribe(() => this.loadRepays());
  }

  loadRecharges() {
    this.state.set('loadingRechargesSub', true);
    this.httpService
      .getServiceRecharge(this.serviceId)
      .pipe(finalize(() => this.state.set('loadingRechargesSub', false)))
      .subscribe((recharges: any[]) => {
        this.state.set('rechargesSub', recharges);
      });
  }

  createRecharges(data: any): Observable<any> {
    return this.httpService.createRechargeService(this.serviceId, data);
  }

  updateRecharge(rechargeId: string, data: any): Observable<any> {
    return this.httpService.updateRechargeService(
      this.serviceId,
      rechargeId,
      data
    );
  }

  deleteRecharge(rechargeId: string) {
    this.httpService
      .deleteRechargeService(this.serviceId, rechargeId)
      .subscribe(() => this.loadRecharges());
  }

  loadRelatedServices() {
    const serviceDetail = [];
    if (this.selectedService.attributes.parent_service_id) {
      serviceDetail.push(
        this.httpService.getService(
          this.selectedService.attributes.parent_service_id
        )
      );
    } else if (this.selectedService.relationships.linked_services.data) {
      this.selectedService.relationships.linked_services.data.forEach(
        (service: any) => {
          serviceDetail.push(this.httpService.getService(service.id));
        }
      );
    }
    // tslint:disable-next-line: deprecation
    forkJoin(...serviceDetail).subscribe((relatedServices: any) => {
      this.state.set('relatedServicesSub', relatedServices);
    });
  }

  loadAdditionalWorks() {
    this.state.set('loadingBudgetsSub', true);
    const listAssessments = [];

    this.httpService
      .getServiceAdditionalWorks(this.serviceId)
      .subscribe(async (data: any) => {
        const additionalWorkSort = data.map((item: any) => item.id);

        for (const additionalWork of additionalWorkSort) {
          await this.httpService
            .getAdditionalWorkInfo(this.serviceId, additionalWork)
            .then(async (response: any) => {
              let assessment = {
                assessment: {
                  ...response.data.attributes,
                  id: response.data.id
                },
                items: response.included,
                payment: null
              };

              if (this.assessmentHasPayment(response)) {
                await this.httpService
                  .getPaymentInfo(
                    this.serviceId,
                    response.data.id,
                    response.data.attributes.last_payment_id
                  )
                  .then((payment: any) => {
                    assessment = { ...assessment, payment };
                  });
              }

              listAssessments.push(assessment);
            });
        }

        this.state.set('additionalWorksSub', listAssessments);
        this.state.set('loadingBudgetsSub', false);
      });
  }

  deleteAdditionalWork(additionalWorkId: string) {
    this.httpService
      .deleteAdditionalWorkInfo(this.serviceId, additionalWorkId)
      .subscribe(() => this.loadAdditionalWorks());
  }

  getSpace(spaceId: string): void {
    this.httpService
      .getSpace(spaceId)
      .subscribe((data: any) => this.state.set('spaceOnServiceSub', data.data));
  }

  loadHomeServiceChecklists(): void {
    this.state.set('loadingHomeServiceChecklistsSub', true);
    this.httpService
      .getHomeServiceChecklists(this.serviceId)
      .pipe(
        finalize(() => this.state.set('loadingHomeServiceChecklistsSub', false))
      )
      .subscribe((homeServiceChecklists: HomeServiceChecklist[]) => {
        this.state.set('homeServiceChecklistsSub', homeServiceChecklists);
      });
  }

  updateHomeServiceChecklist(
    checklistID: string,
    params: any = {}
  ): Observable<any> {
    return this.httpService.updateHomeServiceChecklist(
      this.serviceId,
      checklistID,
      params
    );
  }

  private assessmentHasPayment(assessment: any): boolean {
    return (
      assessment &&
      assessment.data &&
      assessment.data.attributes.last_payment_id
    );
  }
}
