var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// tslint:disable: no-void-expression
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
// Component
import { BaseCurrentUserComponent } from '@app/core/extends/base-current-user.component';
// Services
import { CurrentUserFacadeService } from '@app/core/services/current-user/current-user.facade.service';
import { FmxToastr } from '@app/core/services/fmx-toastr/fmx-toastr.service';
import { PermitsService } from '@app/core/services/permits/permits.service';
import { ComplaintsFacadeService } from '@app/features/complaints/services/complaints-facade.service';
// Utils
import { checkErrors } from '@app/fmx-shared/utils/map-key-form.utils';
var AddEditComplaintModalComponent = /** @class */ (function (_super) {
    __extends(AddEditComplaintModalComponent, _super);
    function AddEditComplaintModalComponent(dialogRef, formBuilder, facade, fmxToastr, currentUserFacade, permitsService, data) {
        var _this = _super.call(this, currentUserFacade, permitsService) || this;
        _this.dialogRef = dialogRef;
        _this.formBuilder = formBuilder;
        _this.facade = facade;
        _this.fmxToastr = fmxToastr;
        _this.currentUserFacade = currentUserFacade;
        _this.permitsService = permitsService;
        _this.data = data;
        _this.complaintProviderStatus = [];
        _this.complaintsKind = [];
        _this.severities = [];
        _this.statuses = [];
        return _this;
    }
    Object.defineProperty(AddEditComplaintModalComponent.prototype, "attributes", {
        get: function () {
            return this.complaint && this.complaint.attributes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEditComplaintModalComponent.prototype, "serviceIdComplaint", {
        get: function () {
            return this.complaint && this.complaint.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEditComplaintModalComponent.prototype, "modalParams", {
        get: function () {
            return this.data;
        },
        enumerable: true,
        configurable: true
    });
    AddEditComplaintModalComponent.prototype.ngOnInit = function () {
        this.complaint = this.data && this.data.complaint;
        this.service = this.data && this.data.service;
        this.serviceId = this.service ? this.service.id : this.serviceIdComplaint;
        if (!this.isClient()) {
            this.onInitOperator();
        }
        else {
            this.onInitClient();
        }
    };
    AddEditComplaintModalComponent.prototype.closeModal = function (refresh) {
        if (refresh === void 0) { refresh = false; }
        this.dialogRef.close(refresh);
    };
    AddEditComplaintModalComponent.prototype.submitComplaint = function () {
        this.complaint ? this.editComplaint() : this.createComplaint();
    };
    AddEditComplaintModalComponent.prototype.createComplaint = function () {
        var _this = this;
        this.facade.createComplaint(this.serviceId, this.form.value).subscribe(function () {
            _this.fmxToastr.success('Reclamación añadida correctamente');
            _this.closeModal(true);
        }, function (error) {
            var errors = error.error.errors;
            checkErrors(errors, _this.form);
        });
    };
    AddEditComplaintModalComponent.prototype.editComplaint = function () {
        var _this = this;
        this.facade.editComplaint(this.serviceId, this.form.value).subscribe(function () {
            _this.fmxToastr.success('Reclamación editada correctamente');
            _this.closeModal(true);
        }, function (error) {
            var errors = error.error.errors;
            checkErrors(errors, _this.form);
        });
    };
    AddEditComplaintModalComponent.prototype.onInitOperator = function () {
        this.getKinds();
        this.getComplaintProviderStatus();
        this.getSeverities();
        this.getStatuses();
        this.initForm();
    };
    AddEditComplaintModalComponent.prototype.onInitClient = function () {
        this.initFormClient();
    };
    AddEditComplaintModalComponent.prototype.initForm = function () {
        this.form = this.formBuilder.group({
            description: [this.getValue('description')],
            comment_to_provider: [this.getValue('comment_to_provider')],
            comment_to_client: [this.getValue('comment_to_client')],
            provider_access: [this.getValueBoolean('provider_access')],
            client_access: [this.getValueBoolean('client_access')],
            provider_status: [this.getValue('provider_status')],
            kind: [this.getValue('kind')],
            severity: [this.getValue('severity')],
            title: [this.getValue('title')],
            status: [
                this.getValue('status') === '' ? 'pending' : this.getValue('status')
            ],
            contact_name: [this.getContactValue('contact_name')],
            contact_phone: [this.getContactValue('contact_phone')]
        });
    };
    AddEditComplaintModalComponent.prototype.initFormClient = function () {
        this.form = this.formBuilder.group({
            description: [this.getValue('description')],
            kind: ['other'],
            severity: ['medium'],
            title: [this.getValue('title')],
            contact_name: ["" + this.currentUser.attributes.name],
            contact_phone: [this.currentUser.attributes.phone]
        });
    };
    AddEditComplaintModalComponent.prototype.getValue = function (formControlName) {
        return (this.attributes && this.attributes[formControlName]) || '';
    };
    AddEditComplaintModalComponent.prototype.getValueBoolean = function (formControlName) {
        return (this.attributes && this.attributes[formControlName]) || false;
    };
    AddEditComplaintModalComponent.prototype.getContactValue = function (formControlName) {
        return this.service
            ? this.service.attributes[formControlName]
            : this.complaint.attributes[formControlName];
    };
    AddEditComplaintModalComponent.prototype.getKinds = function () {
        var _this = this;
        this.facade
            .getComplaintKinds()
            .subscribe(function (response) { return (_this.complaintsKind = response); });
    };
    AddEditComplaintModalComponent.prototype.getSeverities = function () {
        var _this = this;
        this.facade
            .getSeveritiesComplaints()
            .subscribe(function (response) { return (_this.severities = response); });
    };
    AddEditComplaintModalComponent.prototype.getStatuses = function () {
        var _this = this;
        this.facade
            .getComplaintStatuses()
            .subscribe(function (response) { return (_this.statuses = response); });
    };
    AddEditComplaintModalComponent.prototype.getComplaintProviderStatus = function () {
        var _this = this;
        this.facade
            .getComplaintProviderStatus()
            .subscribe(function (response) { return (_this.complaintProviderStatus = response); });
    };
    return AddEditComplaintModalComponent;
}(BaseCurrentUserComponent));
export { AddEditComplaintModalComponent };
