import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import {
  filterResponseByActive,
  responseToSelectOptions,
  toSelectOptions
} from './../../../fmx-shared/utils/data-utils';
import { SelectOption } from './../../models/select-option.model';

export let SERVICE_LOG_STATUS = {};
@Injectable()
export class DataService {
  statusesByUser: SelectOption[];
  specialtiesAvailable: SelectOption[];
  batchesAvailable: SelectOption[];

  constructor(private http: HttpClient) {}

  getSpecialties(paramToFilter?: string): Observable<SelectOption[]> {
    const endpoint = 'data/specialties';
    let params = new HttpParams();

    if (paramToFilter) {
      params = params.set(`${paramToFilter}`, 'true');
    }

    return this.http.get(endpoint, { params }).pipe(
      map(toSelectOptions),
      tap((response: SelectOption[]) => (this.specialtiesAvailable = response))
    );
  }

  getServicesTypes(specialtyId: string | number): Observable<SelectOption[]> {
    const endpoint = 'data/service_types';
    const params = new HttpParams().set('specialty', `${specialtyId}`);

    return this.http.get(endpoint, { params }).pipe(map(toSelectOptions));
  }

  getImageKinds(): Observable<SelectOption[]> {
    const endpoint = `data/image_kinds`;

    return this.http
      .get(endpoint)
      .pipe(
        map((response: any) =>
          responseToSelectOptions(response, 'name', 'codename')
        )
      );
  }

  getStatuses(userRole?: string): Observable<SelectOption[]> {
    const endPoint = 'data/statuses';
    let paramsEndPoint = new HttpParams();

    if (userRole) {
      paramsEndPoint = paramsEndPoint = paramsEndPoint.set('for', userRole);
    }

    return this.http.get(endPoint, { params: paramsEndPoint }).pipe(
      map((response: any) => {
        const statuses = responseToSelectOptions(
          response.data,
          'name',
          'codename'
        );
        this.statusesByUser = statuses;

        return statuses;
      })
    );
  }

  getReasons(): Observable<SelectOption[]> {
    const endpoint = 'data/reasons';

    return this.http
      .get(endpoint)
      .pipe(
        map((response: any) =>
          responseToSelectOptions(response, 'name', 'codename')
        )
      );
  }

  getDuePeriods(): Observable<SelectOption[]> {
    const endpoint = 'data/due_periods';

    return this.http
      .get(endpoint)
      .pipe(
        map((response: any) =>
          responseToSelectOptions(response, 'name', 'codename')
        )
      );
  }

  getCountries(): Observable<SelectOption[]> {
    const endpoint = 'data/countries';
    const params = new HttpParams().set('sort_by', 'name');

    return this.http.get(endpoint, { params }).pipe(map(toSelectOptions));
  }

  getStates(countryId: string): Observable<SelectOption[]> {
    const endpoint = 'data/states';
    const params = new HttpParams()
      .set('sort_by', 'name')
      .set('country', countryId);

    return this.http.get(endpoint, { params }).pipe(map(toSelectOptions));
  }

  getAllStates(): Observable<SelectOption[][]> {
    return this.getCountries().pipe(
      mergeMap((response: SelectOption[]) => {
        const observables: Observable<SelectOption[]>[] = [];

        response.forEach((item: SelectOption) =>
          observables.push(this.getStates(`${item.value}`))
        );

        return forkJoin(observables);
      })
    );
  }

  getOperatorLevels(): Observable<SelectOption[]> {
    const endpoint = `data/operator_levels`;

    return this.http
      .get(endpoint)
      .pipe(
        map((response: any) =>
          responseToSelectOptions(response, 'name', 'codename')
        )
      );
  }

  getUserLevels(): Observable<SelectOption[]> {
    const endpoint = `data/levels`;

    return this.http
      .get(endpoint)
      .pipe(
        map((response: any) =>
          responseToSelectOptions(response, 'name', 'codename')
        )
      );
  }

  getBatches(): Observable<SelectOption[]> {
    const endpoint = 'data/batches';

    return this.http.get(endpoint).pipe(
      map((response: any) =>
        responseToSelectOptions(response, 'name', 'codename')
      ),
      tap((response: SelectOption[]) => (this.batchesAvailable = response))
    );
  }

  getServiceLogActions(): Observable<SelectOption[]> {
    const endpoint = 'data/service_log_actions';

    return this.http.get(endpoint).pipe(
      map((response: any) =>
        responseToSelectOptions(response, 'name', 'codename')
      ),
      tap((response: SelectOption[]) => {
        response.map((item: SelectOption) => {
          SERVICE_LOG_STATUS = {
            ...SERVICE_LOG_STATUS,
            [`${item.value}`]: item.name
          };
        });
      })
    );
  }

  getReasonsServiceRejection(target: string): Observable<SelectOption[]> {
    const endpoint = `data/service_rejection_reasons?target=${target}`;

    return this.http.get(endpoint).pipe(
      map((response: any) => filterResponseByActive(response.data)),
      map((response: any) => responseToSelectOptions(response, 'name', 'id'))
    );
  }

  getReasonsBudgetRejection(): Observable<SelectOption[]> {
    const endpoint = 'data/budget_rejection_reasons';

    return this.http
      .get(endpoint)
      .pipe(
        map((response: any) =>
          responseToSelectOptions(response, 'name', 'codename')
        )
      );
  }

  getDocumentKinds(): Observable<SelectOption[]> {
    const endpoint = `data/document_kinds`;

    return this.http
      .get(endpoint)
      .pipe(
        map((response: any) =>
          responseToSelectOptions(response, 'name', 'codename')
        )
      );
  }

  getActivityTypes(): Observable<SelectOption[]> {
    const endpoint = `data/activity_types`;

    return this.http.get(endpoint).pipe(map(toSelectOptions));
  }

  getApplianceTypes(): Observable<SelectOption[]> {
    const endpoint = `data/appliance_types`;

    return this.http.get(endpoint).pipe(map(toSelectOptions));
  }

  getZones(): Observable<SelectOption[]> {
    const endpoint = `data/zones`;

    return this.http
      .get(endpoint)
      .pipe(
        map((response: any) =>
          responseToSelectOptions(response.data, 'name', 'id')
        )
      );
  }

  getSizes(): Observable<SelectOption[]> {
    const endpoint = `data/company_aggrupation_sizes`;

    return this.http
      .get(endpoint)
      .pipe(
        map((response: any) =>
          responseToSelectOptions(response, 'name', 'codename')
        )
      );
  }

  getDeletionCauses(): Observable<SelectOption[]> {
    const endpoint = `data/deletion_causes`;

    return this.http
      .get(endpoint)
      .pipe(
        map((response: any) =>
          responseToSelectOptions(response.data, 'name', 'codename')
        )
      );
  }

  getComplaintKinds(): Observable<SelectOption[]> {
    const endpoint = 'data/complaint_kinds';

    return this.http
      .get(endpoint)
      .pipe(
        map((data: any[]) => responseToSelectOptions(data, 'name', 'codename'))
      );
  }

  getSeveritiesComplaints(): Observable<SelectOption[]> {
    const endpoint = 'data/complaint_severities';

    return this.http
      .get(endpoint)
      .pipe(
        map((data: any[]) => responseToSelectOptions(data, 'name', 'codename'))
      );
  }

  getComplaintsStatuses(): Observable<SelectOption[]> {
    const endPoint = 'data/complaint_statuses';

    return this.http
      .get(endPoint)
      .pipe(
        map((data: any[]) => responseToSelectOptions(data, 'name', 'codename'))
      );
  }

  getComplaintProviderStatuses(): Observable<SelectOption[]> {
    const endPoint = 'data/complaint_provider_statuses';

    return (
      this.http
        .get(endPoint)
        // tslint:disable-next-line: no-unnecessary-callback-wrapper
        .pipe(
          map((data: any[]) =>
            responseToSelectOptions(data, 'name', 'codename')
          )
        )
    );
  }
}
